import { useDetermineLocale, useSDMContextProps } from '@/hooks';
import { SDMContexts } from '@ldp/sdm-contexts';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import styles from './container.module.css';

import CartController from '@/components/cart-controller';
import GlobalState from '@/components/global-state';
import { refreshTokenFlow, saveTokens, setLastVisitedPage } from '@/utils';
import * as Sentry from '@sentry/react';
import messages, { type Locale } from 'I18n/translations';
import { usePathname } from 'next/navigation';
import { Spinner } from 'Shared/spinner';
import { LayoutProps } from './layout.interface';
import { isPharmaprixBanner, cleanUp } from '@/utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function Layout({ children, cartData, viewDefinitionResults, kamFlags }: LayoutProps) {
  const locale = useDetermineLocale();
  const [preferredLocale, setPreferredLocale] = useState<Locale>('en');
  const sdmContextProps = useSDMContextProps();
  const pathname = usePathname();
  const [refreshAvailable, setRefreshAvailable] = useState(false);

  const refreshFlow = async () => {
    const tokens = await refreshTokenFlow();
    if (tokens !== null) {
      Sentry.captureMessage('Refresh Token successfully');
      console.log('saving tokens', tokens);
      saveTokens(tokens);
      const isUnauthorized = Number(viewDefinitionResults?.error?.status) === 401;
      setRefreshAvailable(!isUnauthorized);
      if (isUnauthorized) {
        window.location.href = pathname;
      }
    } else {
      setRefreshAvailable(true);
    }
  };
  useEffect(() => setPreferredLocale(locale), [locale]);
  useEffect(() => setLastVisitedPage(pathname), [pathname]);
  useEffect(() => {
    const domains = isPharmaprixBanner()
      ? ['.pharmaprix.ca', process.env.NEXT_PUBLIC_COOKIE_DOMAIN_FR_URL]
      : ['.shoppersdrugmart.ca', process.env.NEXT_PUBLIC_COOKIE_DOMAIN_EN_URL];
    domains.forEach((domain) => cleanUp(domain));
    refreshFlow();
  }, []);

  return refreshAvailable ? (
    <GlobalState
      cartData={cartData ?? undefined}
      viewDefinitionResults={viewDefinitionResults ?? undefined}
      kamFlags={kamFlags ?? null}
    >
      <SDMContexts {...sdmContextProps}>
        <IntlProvider
          locale={preferredLocale}
          messages={messages[preferredLocale]}
          onError={() => {
            return 'no error';
          }}
        >
          <QueryClientProvider client={queryClient}>
            <CartController>
              <div className={styles.container} id="main-content">
                {children}
              </div>
            </CartController>
          </QueryClientProvider>
        </IntlProvider>
      </SDMContexts>
    </GlobalState>
  ) : (
    <Spinner />
  );
}

import { B3Propagator } from '@opentelemetry/propagator-b3';
import * as opentelemetry from '@opentelemetry/api';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { registerInstrumentations } from '@opentelemetry/instrumentation';

registerInstrumentations({
  instrumentations: [],
});

opentelemetry.propagation.setGlobalPropagator(new B3Propagator());

const provider = new WebTracerProvider();
provider.register();

export const tracerClient = opentelemetry.trace.getTracer('sdm-tracer');
